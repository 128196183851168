import { createSlice, isAnyOf } from "@reduxjs/toolkit";

//import actions
import {
  addToast,
  hideToast,
  loading,
  modalClose,
  modalConfirm,
  modalConfirmNo,
  modalError,
  modalErrorOk,
} from "redux/commonRedux/commonActions";
import {
  fetchUserByUsernameSuccess,
  modalPasswordLost,
  userLoggedIn,
  userLoggedOut,
  userPasswordLostSuccess,
} from "redux/userRedux/userActions";

import {
  addPatternSuccess,
  addPitSuccess,
  modalNewPit,
  modalSurveyImport,
  openModalNewPattern,
  processDrillPlanSuccess,
} from "redux/fieldRedux/fieldActions";
import {
  addVehicleEventSuccess,
  deleteVehicleSuccess,
  openModalArchives,
  openModalMaintenance,
} from "redux/machinesRedux/machinesActions";

let loadCounter = 0;
const initialState = {
  modal: {},
  loading: false,
  user: {},
  usersettings: {},
  toast: {},
  action: {
    name: null,
    data: {},
  },
  route: {},
};

export const commonSlice = createSlice({
  name: "commonReducer",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase("@@router/LOCATION_CHANGE", (state, action) => {
        state.route = action.payload.location;
      })

      .addCase(loading, (state, action) => {
        loadCounter = action.payload ? loadCounter + 1 : loadCounter - 1;
        //just made to avoid having negative load counter
        loadCounter = loadCounter < 1 ? 0 : loadCounter;
        state.loading = loadCounter > 0;
      })

      .addCase(userLoggedIn, (state, action) => {
        state.user = action.payload;
      })

      .addCase(userLoggedOut, (state, action) => {
        state.user = initialState.user;
        state.usersettings = initialState.usersettings;
      })

      .addCase(fetchUserByUsernameSuccess, (state, action) => {
        state.usersettings = action.payload;
      })

      // // SHOW TOAST
      .addCase(addToast, (state, action) => {
        state.toast = action.payload;
      })

      .addCase(hideToast, (state, action) => {
        state.toast = initialState.toast;
      })

      // // *** .addMatcher with isAnyOf for multiple actions ***
      .addMatcher(
        isAnyOf(
          modalError,
          modalConfirm,
          modalPasswordLost,
          modalNewPit,
          openModalNewPattern,
          modalSurveyImport,
          openModalMaintenance,
          openModalArchives
        ),
        (state, action) => {
          state.modal = action.payload;
          state.modal.type = action.type;
          state.loading = initialState.loading;
        }
      )

      .addMatcher(
        isAnyOf(
          modalErrorOk,
          modalClose,
          modalConfirmNo,
          userPasswordLostSuccess,
          addPitSuccess,
          addPatternSuccess,
          processDrillPlanSuccess,
          addVehicleEventSuccess,
          deleteVehicleSuccess
        ),
        (state, action) => {
          state.modal = initialState.modal;
          state.action = initialState.action;
          loadCounter = 0;
        }
      );
  },
});
