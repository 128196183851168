import produce from "immer";
import { formatDuration } from "date-fns";
import {
  average,
  createLocation,
  getBoundingBox,
  toLatitudeLongitude,
} from "geolocation-utils";

var _ = require("lodash");

function arrayToTree(inputArray, parentName, childrenName) {
  var clonedArray = cloneObject(inputArray);
  if (!clonedArray || clonedArray.length === 0) return [];

  var nodes = {};
  var tree = clonedArray.filter(function (obj) {
    var id = obj[childrenName],
      parentId = obj[parentName];

    nodes[id] = _.defaults(obj, nodes[id], { children: [] });
    parentId &&
      (nodes[parentId] = nodes[parentId] || { children: [] })["children"].push(
        obj
      );

    return !parentId;
  });
  return tree;
}

function cloneObject(inputObject) {
  try {
    return _.cloneDeep(inputObject);
  } catch (Error) {
    return inputObject;
  }
}

function generateCheckBoxTreeArray(
  inputArray,
  selectedEntity,
  valueKey,
  labelKey
) {
  if (!inputArray || inputArray.length <= 0) return [];
  return produce(inputArray, (draft) => {
    draft.forEach((x) => {
      x.value = x[valueKey];
      x.label = x[labelKey];
      x.showCheckbox = false;
      x.className =
        x[valueKey] === selectedEntity[valueKey] ? "isSelected" : "";
    });
  });
}

/**
 * Returns an array containing all the parents of an element
 * @param {array} inputArray Array to search
 * @param {string} key Key of item you want to find the parents for
 * @param {string} parentName Name of the property holding the parent link
 * @param {string} childrenName Name of the property holding the children link
 */
function findParents(inputArray, key, parentName, childrenName) {
  let parentList = [];
  let foundParent = true;
  while (foundParent) {
    let theParent = findParent(inputArray, key, childrenName);
    if (theParent) {
      parentList.push(theParent);
      key = theParent[parentName];
    } else {
      foundParent = false;
    }
  }
  return parentList;
}

/**
 * Returns an element's parent
 * @param {array} inputArray Array to search
 * @param {string} key Key of item you want to find the parent for
 * @param {string} childrenName Name of the property holding the children link
 */
function findParent(inputArray, key, childrenName) {
  let clonedArray = cloneObject(inputArray);
  if (!clonedArray.length) return null;
  return clonedArray.find((x) => x[childrenName] === key);
}

//returns the object with only specified properties
function cleanObject(o, properties) {
  return Object.keys(o).reduce((acc, key) => {
    if (properties.indexOf(key) !== -1) {
      return { ...acc, [key]: o[key] };
    }
    return acc;
  }, {});
}

//return true if the user has at least one of the required roles
function checkRoles(userRoles, requiredRoles) {
  if (!userRoles) return false;
  //if required role has a star, any user can access
  if (requiredRoles.indexOf("*") > -1) return true;
  for (var i = 0; i < userRoles.length; i++) {
    if (requiredRoles.indexOf(userRoles[i]) > -1) return true;
  }
  return false;
}

//define which task should be active
function getActiveTask(stageList) {
  //console.log(stageList);
  let taskListOrder = [];
  stageList.forEach((sL) => {
    sL.toggleTasks
      .filter((x) => x.value === 0)
      .forEach((tT) => {
        taskListOrder.push(tT.order);
      });
  });

  return taskListOrder.length > 0 ? Math.min(...taskListOrder) : -1;
}

function capitalizeString(string) {
  if (!string) return string;
  return string.replace(/\b\w/g, (l) => l.toUpperCase());
}

function getDateTime(dateObject) {
  if (dateObject.getFullYear() === 1) return "Invalid";
  const date = `${dateObject.getDate().toString().padStart(2, "0")}.${(
    dateObject.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}`;
  const time = `${dateObject
    .getHours()
    .toString()
    .padStart(2, "0")}:${dateObject.getMinutes().toString().padStart(2, "0")}`;

  return `${date} ${time}`;
}

function addTimeToDate(date, minutes) {
  let dateObject = new Date(date);
  dateObject.setTime(dateObject.getTime() + minutes * 60 * 1000);
  return dateObject;
}

function getDateForPicker(dateObject) {
  const year = `${dateObject.getFullYear().toString()}`;
  const day = `${dateObject.getDate().toString().padStart(2, "0")}`;
  const month = `${(dateObject.getMonth() + 1).toString().padStart(2, "0")}`;
  return `${year}-${month}-${day}`;
}

function getDuration(dateObject) {
  const fromDate = dateObject;
  const toDate = new Date();
  return formatDuration({ seconds: (toDate - fromDate) / 1000 });
}

function minutesToDuration(minutes) {
  var h = Math.floor(minutes / 60);
  var m = minutes % 60;
  h = h < 10 ? "0" + h : h;
  m = m < 10 ? "0" + m : m;
  return h + ":" + m;
}

function hoursToDuration(hours) {
  var d = 0;
  if (hours > 24) {
    d = Math.floor(hours / 24);
    hours = hours - d * 24;
  }
  var h = Math.floor(hours);
  var m = Math.floor((hours - h) * 60);
  h = h < 10 ? "0" + h : h;
  m = m < 10 ? "0" + m : m;

  if (d > 0) {
    return d + ":" + h + ":" + m;
  } else {
    return h + ":" + m;
  }
}

function getPatternsAverage(patterns, defaultValue) {
  let locList = [];
  for (let i = 0; i < patterns.length; i++) {
    for (let j = 0; j < patterns[i].boundaries.length; j++) {
      //console.log(patterns[i].boundaries[j]);
      locList.push(
        createLocation(
          patterns[i].boundaries[j].latitude,
          patterns[i].boundaries[j].longitude,
          "LatLon"
        )
      );
    }
  }
  let calculatedAverage = average(locList);
  if (!calculatedAverage) {
    return defaultValue;
  }
  calculatedAverage = toLatitudeLongitude(calculatedAverage);
  return [calculatedAverage.latitude, calculatedAverage.longitude];
  //calculatedAverage = [calculatedAverage.latitude,calculatedAverage.longitude];
  //return calculatedAverage?:null;
}

function getPatternBox(locationList) {
  let locList = [];
  for (let i = 0; i < locationList.length; i++) {
    locList.push(
      createLocation(
        locationList[i].latitude,
        locationList[i].longitude,
        "LatLon"
      )
    );
  }
  return getBoundingBox(locList);
}

function getVehicleHours(shiftReport) {
  let hours = {
    engine: 0,
    percussive: 0,
  };

  var engineHoursStartObj,
    engineHoursEndObj,
    percussiveHoursStartObj,
    percussiveHoursEndObj;
  var foundEngineHoursStart = 0;
  var foundEngineHoursEnd = 0;
  var foundPercussiveHoursStart = 0;
  var foundPercussiveHoursEnd = 0;

  if (shiftReport && shiftReport.info && shiftReport.info.length > 0) {
    engineHoursStartObj = shiftReport.info.find(
      (x) => x.key === "engineHoursStart"
    );
    engineHoursEndObj = shiftReport.info.find(
      (x) => x.key === "engineHoursEnd"
    );
    percussiveHoursStartObj = shiftReport.info.find(
      (x) => x.key === "percussiveHoursStart"
    );
    percussiveHoursEndObj = shiftReport.info.find(
      (x) => x.key === "percussiveHoursEnd"
    );
  }

  if (engineHoursStartObj) {
    foundEngineHoursStart = engineHoursStartObj.value;
  }
  if (engineHoursEndObj) {
    foundEngineHoursEnd = engineHoursEndObj.value;
  }
  if (percussiveHoursStartObj) {
    foundPercussiveHoursStart = percussiveHoursStartObj.value;
  }
  if (percussiveHoursEndObj) {
    foundPercussiveHoursEnd = percussiveHoursEndObj.value;
  }

  hours.engine =
    foundEngineHoursEnd > 0 ? foundEngineHoursEnd : foundEngineHoursStart;
  hours.percussive =
    foundPercussiveHoursEnd > 0
      ? foundPercussiveHoursEnd
      : foundPercussiveHoursStart;

  return hours;
}

function isURL(URL) {
  // eslint-disable-next-line
  const urlRegex =
    /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)$/;
  return urlRegex.test(URL);
}

function isOwnURL(URL) {
  // eslint-disable-next-line
  const urlRegex = /^\/.*?$/;
  return urlRegex.test(URL);
}

export {
  arrayToTree,
  generateCheckBoxTreeArray,
  findParents,
  cleanObject,
  checkRoles,
  capitalizeString,
  getDateTime,
  getActiveTask,
  getPatternsAverage,
  getPatternBox,
  getDateForPicker,
  getDuration,
  addTimeToDate,
  getVehicleHours,
  minutesToDuration,
  hoursToDuration,
  isURL,
  isOwnURL,
};
