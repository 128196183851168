import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { Add, Info } from "@mui/icons-material";

//import utils
import { GetLoggedInUser } from "api/auth";

//Import css
import "./modalMaintenance.scss";

//Import Actions
import { modalClose } from "redux/commonRedux/commonActions";
import { addVehicleEvent } from "redux/machinesRedux/machinesActions";

function ModalMaintenance(props) {
  const { t } = useTranslation();

  // // ----------redux store useDispatch & useSelector --------------------
  const dispatch = useDispatch();
  // const state = useSelector((state) => (state));

  const user = GetLoggedInUser();
  const [maintenanceEvent, setMaintenanceEvent] = useState({
    startedAt: props.modal.maintenanceData.startedAt || dayjs(new Date()),
    endedAt: props.modal.maintenanceData.endedAt || dayjs(new Date()),
    type: props.modal.maintenanceData.eventType || "SCHEDULED_SERVICE",
    report: props.modal.maintenanceData.notes || "",
  });
  const [maintenanceErrors, setMaintenanceErrors] = useState({
    report: "",
    endedAt: "",
  });

  React.useEffect(() => {
    formValidation();
    // eslint-disable-next-line
  }, [maintenanceEvent]);

  const handleDateTimePickerChange = (property, dateObject) => {
    setMaintenanceEvent({ ...maintenanceEvent, [property]: dateObject });
  };

  const handleValueChange = (e) => {
    setMaintenanceEvent({
      ...maintenanceEvent,
      [e.target.name]: e.target.value,
    });
  };

  const isFormValid = () => {
    let isNotEmpty = false;
    Object.keys(maintenanceErrors).forEach(function (key, index) {
      if (maintenanceErrors[key] !== "") isNotEmpty = true;
    });
    if (isNotEmpty) return false;
    if (maintenanceEvent.report === "") return false;
    return true;
  };
  const formValidation = () => {
    if (props.modal.mode === "info") return; //no need to do form validation if info mode
    let newErrors = {
      endedAt: "",
      report: "",
    };
    let formattedStartDate = maintenanceEvent.startedAt.format();
    let formattedEndDate = maintenanceEvent.endedAt.format();
    if (formattedStartDate > formattedEndDate) {
      newErrors.endedAt = t(
        "components.modals.modalMaintenance.error_end_date_before_start_date"
      );
    }
    if (maintenanceEvent.report === "") {
      newErrors.report = t(
        "components.modals.modalMaintenance.error_report_required"
      );
    }
    setMaintenanceErrors(newErrors);
    return;
  };

  const handleSave = () => {
    if (maintenanceErrors.endedAt !== "") return;
    //startedAt should be before endedAt
    let maintenanceData = {
      vehicleID: props.modal.vehicle.id,
      vehicleSerial: props.modal.vehicle.serial,
      startedAt: maintenanceEvent.startedAt,
      endedAt: maintenanceEvent.endedAt,
      eventType: maintenanceEvent.type,
      notes: maintenanceEvent.report,
      reportedBy: user.username,
    };
    //console.log(maintenanceData);
    dispatch(addVehicleEvent(maintenanceData));
  };

  let dialogTitle = (
    <DialogTitle>
      <Add />{" "}
      <span>
        {t("components.modals.modalMaintenance.text_primary_title_new")}
      </span>
    </DialogTitle>
  );
  if (props.modal.mode === "info") {
    dialogTitle = (
      <DialogTitle>
        <Info />{" "}
        <span>
          {t("components.modals.modalMaintenance.text_primary_title_info")}
        </span>
      </DialogTitle>
    );
  }

  return (
    <Dialog
      open={true}
      className="modalMaintenance"
      onClose={() => dispatch(modalClose())}
      maxWidth={"sm"}
      fullWidth={true}
    >
      {dialogTitle}
      <DialogContent>
        <Grid container direction="column">
          <Grid item xs={12}>
            <Grid container direction="row" className="dataElement">
              <Grid item xs={6} className="data">
                <TextField
                  label={t("components.modals.modalMaintenance.text_user")}
                  fullWidth
                  margin="normal"
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                  disabled={true}
                  value={user.username}
                />
              </Grid>
              <Grid item xs={6} className="data">
                <TextField
                  label={t(
                    "components.modals.modalMaintenance.text_vehicle_serial"
                  )}
                  fullWidth
                  margin="normal"
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                  disabled={true}
                  value={props.modal.vehicle.serial}
                />
              </Grid>
            </Grid>
            <Grid container direction="row" className="dataElementDateTime">
              <Grid item xs={12} className="data">
                <DateTimePicker
                  className="startedAt"
                  disabled={props.modal.mode === "info"}
                  label={t(
                    "components.modals.modalMaintenance.text_start_time"
                  )}
                  inputFormat="DD/MM/YYYY HH:mm"
                  value={maintenanceEvent.startedAt}
                  onChange={(d) => handleDateTimePickerChange("startedAt", d)}
                  renderInput={(params) => (
                    <TextField variant="standard" {...params} />
                  )}
                />
              </Grid>
            </Grid>
            <Grid container direction="row" className="dataElement">
              <Grid item xs={12} className="data">
                <DateTimePicker
                  className="endedAt"
                  disabled={props.modal.mode === "info"}
                  label={t("components.modals.modalMaintenance.text_end_time")}
                  inputFormat="DD/MM/YYYY HH:mm"
                  value={maintenanceEvent.endedAt}
                  onChange={(d) => handleDateTimePickerChange("endedAt", d)}
                  renderInput={(params) => (
                    <TextField
                      helperText={maintenanceErrors.endedAt}
                      variant="standard"
                      {...params}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid container direction="row" className="dataElement">
              <Grid item xs={12} className="data">
                <InputLabel className="service-type-input-label" shrink>
                  {t(
                    "components.modals.modalMaintenance.text_maintenance_type"
                  )}
                </InputLabel>
                <Select
                  disabled={props.modal.mode === "info"}
                  value={maintenanceEvent.type}
                  onChange={handleValueChange}
                  name="type"
                  variant="standard"
                  className="service-type-field-dropdown"
                  fullWidth
                >
                  <MenuItem value={"SCHEDULED_SERVICE"}>
                    {t("common.vehicleevents.SCHEDULED_SERVICE")}
                  </MenuItem>
                  <MenuItem value={"UNSCHEDULED_SERVICE"}>
                    {t("common.vehicleevents.UNSCHEDULED_SERVICE")}
                  </MenuItem>
                </Select>
              </Grid>
            </Grid>
            <Grid container direction="row" className="dataElement report">
              <Grid item xs={12} className="data">
                <TextField
                  disabled={props.modal.mode === "info"}
                  label={t("components.modals.modalMaintenance.text_report")}
                  variant="standard"
                  value={maintenanceEvent.report}
                  multiline
                  rows={3}
                  fullWidth
                  margin="normal"
                  required={props.modal.mode === "new"}
                  name="report"
                  id="new_maintenance-report_text"
                  onChange={handleValueChange}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="inherit"
          onClick={() => {
            dispatch(modalClose());
          }}
        >
          {t("components.modals.modalMaintenance.button_cancel")}
        </Button>
        {props.modal.mode === "new" && (
          <Button
            variant="contained"
            id="new_maintenance-save_maintenance"
            color="primary"
            disabled={!isFormValid()}
            onClick={() => {
              handleSave();
            }}
          >
            {t("components.modals.modalMaintenance.button_save")}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default ModalMaintenance;
