import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Button,
  Grid,
  Link,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { CheckCircle, ChevronLeft, Error, Warning } from "@mui/icons-material";
//import actions
import {
  fetchVehicle,
  modifyVehicleState,
  openModalArchives,
  updateStateVehicle,
} from "redux/machinesRedux/machinesActions";
//import components
import FormMachine from "components/machines/formmachine";
import TableMaintenance from "components/machines/tablemaintenance";
//import scss
import "./infomachine.scss";

function InfoMachine(props) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  // // ----------redux store useDispatch & useSelector --------------------
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  let vehicle = state.machines.displayedVehicle;

  const [originalVehicle, setOriginalVehicle] = React.useState({ state: 0 });

  React.useEffect(() => {
    if (!vehicle.serial) {
      dispatch(fetchVehicle(props.id));
    }
    if (!originalVehicle.serial && vehicle.serial) {
      setOriginalVehicle(vehicle);
    }
    //console.log(vehicle);
    // eslint-disable-next-line
  }, [vehicle.serial]);

  const goToMachineList = (e) => {
    if (e) e.preventDefault();
    navigate("/machines/list");
    return;
  };

  const updateState = (val) => {
    dispatch(updateStateVehicle({ ...vehicle, state: val }));
  };

  const handleMachineInfoClick = async () => {
    try {
      await dispatch(
        modifyVehicleState({ id: props.id, state: vehicle.state })
      ).unwrap();
      navigate("/machines/list");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Grid container direction="column" className="infomachineGrid">
      <Grid item xs={12} className="linkBack">
        <Typography variant="body2">
          <Link href="#" onClick={goToMachineList}>
            <ChevronLeft size="small" />{" "}
            <b>{t("screens.machines_info.text_back_to_machines")}</b>
          </Link>
        </Typography>
      </Grid>
      <Grid item xs={12} className="status">
        <Grid container direction="row">
          <Grid item xs={2}>
            <Typography variant="body1">
              {t("screens.machines_info.text_machine_status")}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Select
              id="machine_info-select_state"
              value={vehicle.state || 0}
              onChange={(e) => updateState(e.target.value)}
              fullWidth
              variant="standard"
            >
              <MenuItem
                id="machine_state_menu-operational_state"
                value={0}
                className="stateSelect"
              >
                <CheckCircle sx={{ fontSize: "18px" }} />
                <Typography id="machine-status-text" variant="caption">
                  {t("common.machinestates.state_0")}
                </Typography>
              </MenuItem>
              <MenuItem
                id="machine_state_menu-warning_state"
                value={1}
                className="stateSelect"
              >
                <Warning sx={{ fontSize: "18px" }} />
                <Typography id="machine-status-text" variant="caption">
                  {t("common.machinestates.state_1")}
                </Typography>
              </MenuItem>
              <MenuItem
                id="machine_state_menu-down_state"
                value={2}
                className="stateSelect"
              >
                <Error sx={{ fontSize: "18px" }} />
                <Typography id="machine-status-text" variant="caption">
                  {t("common.machinestates.state_2")}
                </Typography>
              </MenuItem>
              <MenuItem
                id="machine_state_menu-maintenance_state"
                value={3}
                className="stateSelect"
              >
                <CheckCircle sx={{ fontSize: "18px" }} />
                <Typography id="machine-status-text" variant="caption">
                  {t("common.machinestates.state_3")}
                </Typography>
              </MenuItem>
            </Select>
          </Grid>
          <Grid item xs={2} className="saveButton">
            {vehicle.state !== originalVehicle.state && (
              // <Button id="machine_info-save_state_button" variant="contained" color="primary" onClick={() => dispatch(modifyVehicleState({ id: props.id, state: vehicle.state }))}>
              <Button
                id="machine_info-save_state_button"
                variant="contained"
                color="primary"
                onClick={handleMachineInfoClick}
              >
                {t("screens.machines_info.button_save")}
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className="data">
        <Grid container direction="row">
          <Grid item xs={6}>
            <Grid container direction="column" className="formContainer">
              <Grid item xs={12} className="formtitle">
                <Typography variant="h6">
                  {t("screens.machines_info.text_info_machine")}
                </Typography>
              </Grid>
              <Grid item xs={12} className="formmachine">
                <FormMachine
                  mode="info"
                  vehicle={vehicle}
                  dataChanged={() => {}}
                />
              </Grid>
              <Grid item xs={12} className="formbutton">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    dispatch(openModalArchives({ vehicle: vehicle }))
                  }
                >
                  {t("screens.machines_info.button_archives")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} style={{ display: "flex", flex: 1 }}>
            <Grid container direction="column">
              <Grid item xs={12} style={{ display: "flex", flex: 1 }}>
                <Typography variant="h6">
                  {t("screens.machines_info.text_maintenance_history")}
                </Typography>
              </Grid>
              <TableMaintenance
                vehicle={vehicle}
                maintenanceEvents={vehicle.serviceList || []}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default InfoMachine;
