import React, { useState } from "react";
// import { useDispatch, useSelector } from 'react-redux';
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";

import { useTranslation } from "react-i18next";
import { getDateTime } from "utils/tools";
//import scss
import "./listmachines.scss";

function DrillData(props) {
  const { t } = useTranslation();

  // // ----------redux store useDispatch & useSelector --------------------
  // const dispatch = useDispatch()
  // const state = useSelector((state) => (state));

  // let vehicles = state.shift.vehicles
  // let pitsFilter = state.field.filters.pits

  const [collapsed, setCollapsed] = useState(true);
  const { x, i } = props;

  //handle chevron
  const handleToggleClick = () => {
    setCollapsed(!collapsed);
  };

  let chevron = <KeyboardArrowDown />;
  if (!collapsed) chevron = <KeyboardArrowUp />;

  // Get fullname or username for the user
  const userName = (user) => {
    if (!user) return "???";
    if (user.fullname === "" && user.username === "") return "???";
    return user.fullname ? user.fullname : user.username;
  };

  return (
    <Grid item xs={12} key={i}>
      <Card className="machineCard">
        <CardHeader
          title={userName(x.user)}
          action={
            <IconButton aria-label="settings" onClick={handleToggleClick}>
              {chevron}
            </IconButton>
          }
        />
        <CardContent>
          <Grid container direction="column">
            <Grid item xs={12}>
              <Grid container direction="row">
                <Grid item xs={3}>
                  <Typography variant="body2" className={"title"}>
                    {t("screens.shift.text_machine")}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body2">{x.name}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body2" className={"title"}>
                    {t("screens.shift.text_pit")}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body2">{x.drillTask.pit}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container direction="row">
                <Grid item xs={3}>
                  <Typography variant="body2" className={"title"}>
                    {t("screens.shift.text_starttime")}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body2">
                    {getDateTime(new Date(x.shiftReport.startTime))}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body2" className={"title"}>
                    {t("screens.shift.text_pattern")}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body2">{x.drillTask.pattern}</Typography>
                </Grid>
              </Grid>
            </Grid>
            {!collapsed && (
              <React.Fragment>
                <div className="drillingContent">
                  <Grid container direction="row">
                    <Grid item xs={4} className="drillingLabel">
                      <Typography
                        variant="body2"
                        style={{
                          textTransform: "uppercase",
                          fontWeight: "bold",
                        }}
                      >
                        {t("common.tasks.drilling_drilled_holes")}
                      </Typography>
                    </Grid>
                    <Grid item xs={2} className="drillingValue">
                      <Typography variant="body2">
                        {x.drillingData
                          ? x.drillingData.drilling_drilled_holes.toFixed(0)
                          : 0}
                      </Typography>
                    </Grid>
                    <Grid item xs={4} className="drillingLabel">
                      <Typography
                        variant="body2"
                        style={{
                          textTransform: "uppercase",
                          fontWeight: "bold",
                        }}
                      >
                        {t("common.tasks.drilling_holes_per_hour")}
                      </Typography>
                    </Grid>
                    <Grid item xs={2} className="drillingValue">
                      <Typography variant="body2">
                        {x.drillingData
                          ? x.drillingData.drilling_holes_per_hour.toFixed(1)
                          : 0}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container direction="row">
                    <Grid item xs={4} className="drillingLabel">
                      <Typography
                        variant="body2"
                        style={{
                          textTransform: "uppercase",
                          fontWeight: "bold",
                        }}
                      >
                        {t("common.tasks.drilling_speed")}
                      </Typography>
                    </Grid>
                    <Grid item xs={2} className="drillingValue">
                      <Typography variant="body2">
                        {x.drillingData
                          ? x.drillingData.drilling_speed_per_hour.toFixed(1)
                          : 0}{" "}
                        {t("common.units.meters_per_hour")}
                      </Typography>
                    </Grid>
                    <Grid item xs={4} className="drillingLabel">
                      <Typography
                        variant="body2"
                        style={{
                          textTransform: "uppercase",
                          fontWeight: "bold",
                        }}
                      >
                        {t("common.tasks.drilling_total_depth")}
                      </Typography>
                    </Grid>
                    <Grid item xs={2} className="drillingValue">
                      <Typography variant="body2">
                        {x.drillingData
                          ? x.drillingData.drilling_drilled_depth.toFixed(1)
                          : 0}{" "}
                        {t("common.units.meters")}
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              </React.Fragment>
            )}
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}

export default DrillData;
