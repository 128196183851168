import { createSlice } from "@reduxjs/toolkit";

//Import actions
import { fetchShiftData } from "redux/shiftRedux/shiftActions";

const initialState = {
  vehicles: [],
  patterns: [],
};

export const shiftSlice = createSlice({
  name: "shiftReducer",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      // // FETCH SHIFT DATA REDUCER
      .addCase(fetchShiftData.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchShiftData.fulfilled, (state, action) => {
        state.vehicles = action.payload.vehicleList;
        state.patterns = action.payload.patterns;
        state.isLoading = false;
      })
      .addCase(fetchShiftData.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});
