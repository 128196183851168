import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      light: "#757ce8",
      main: "#508CE6",
      dark: "#002884",
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: "Flexo",
  },
});

export default theme;
