import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AppBar, MenuItem, Select, Tab, TableCell, Tabs } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useLocation } from "react-router";

import "./navbar.scss";
//import mslogo from 'static/images/logo_ms.png'
import { routes } from "App";
//import utils
import { checkRoles } from "utils/tools";

function Navbar() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  // // ----------redux store useDispatch & useSelector --------------------
  // const dispatch = useDispatch()
  const state = useSelector((state) => state);

  let user = state.common.user;

  //tab with index 10 is a hidden tab that can be used if you don't want any tab selected
  const [tabList, setTabList] = useState([]);
  const [value, setValue] = useState(11);

  const selectedDropdownValue = 11;
  const [dropdownValue, setDropdownValue] = useState();
  const customClassName =
    dropdownValue === 12 ||
    dropdownValue === 13 ||
    pathname === "/user" ||
    pathname === "/profile"
      ? "select-dropdown"
      : "";
  const customClassNameForMenuItemsFirst =
    dropdownValue === 12 || pathname === "/user"
      ? "menu-items-selected-first"
      : "";
  const customClassNameForMenuItemsSecond =
    dropdownValue === 13 || pathname === "/profile"
      ? "menu-items-selected-second"
      : "";

  useEffect(() => {
    //generate the list of tab for the user, based on his roles
    let tabList = routes
      .filter((x) => x.hasTab && checkRoles(user.roles, x.requiredRoles))
      .sort((a, b) => {
        return a.index - b.index;
      });
    setTabList(tabList);
    //returns the tabindex of the current route (=tab that should be active for the current route)
    let tabIndex = tabList.findIndex((x) => x.tabRegex.test(pathname));
    //find the index of the tab
    //let foundTabIndex = tabList.findIndex(x=>x.index===tabIndex);
    //console.log(tabList,tabIndex,foundTabIndex);
    setValue(tabIndex > -1 ? tabIndex : 11);
    // eslint-disable-next-line
    return () => {
      setDropdownValue();
    };
  }, [user, pathname]);

  const handleChange = (e, val) => {
    setValue(val);
  };

  const handleDropdownUserClick = (value) => {
    setDropdownValue(value);
    navigate("/user");
  };

  const handleDropdownProfileClick = (value) => {
    setDropdownValue(value);
    navigate("/profile");
  };

  let classname = user.username ? "navbar loggedIn" : "navbar notLogged";

  return (
    <AppBar position="static" className={classname}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
      >
        {tabList.length > 0 && (
          <TableCell
            component={() => (
              <Link onClick={() => setValue(10)} to="/" className="brand">
                {/*<div className="mslogo">
                  <img src={mslogo} alt=""/>
                </div>*/}
                <Typography variant="body1" className="logo">
                  {t("tabs.tab_horizon")}
                </Typography>
              </Link>
            )}
          />
        )}
        {
          //Generate Tabs
          tabList.map((x, i) => {
            return (
              <Tab
                label={t(x.label)}
                component={Link}
                to={x.path}
                key={x.path}
                value={i}
              />
            );
          })
        }

        {user && user.roles && (
          <Select
            id="select-admin-dropdown"
            variant="standard"
            className={customClassName}
            value={selectedDropdownValue}
          >
            <MenuItem value={11} disabled className="dropdown-menu-items">
              {t("tabs.tab_administration")}
            </MenuItem>
            {user.roles.includes("director") && (
              <MenuItem
                value={12}
                id={customClassNameForMenuItemsFirst}
                onClick={() => handleDropdownUserClick(12)}
              >
                {t("tabs.tab_users")}
              </MenuItem>
            )}
            <MenuItem
              value={13}
              id={customClassNameForMenuItemsSecond}
              onClick={() => handleDropdownProfileClick(13)}
            >
              {t("screens.profile.text_title_profile")}
            </MenuItem>
          </Select>
        )}
      </Tabs>
    </AppBar>
  );
}

export default Navbar;
