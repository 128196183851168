import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosConfig from "helper/config/axiosConfig";
import { modalError } from "redux/commonRedux/commonActions";

export const fetchShiftData = createAsyncThunk(
  "shift/shiftdata",
  async (pits, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosConfig().post("/shiftdata", { pits });
      return response.data;
    } catch (err) {
      dispatch(modalError(err));
      return rejectWithValue([], { data: err.response.data });
    }
  }
);
