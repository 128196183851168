import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

import {
  Button,
  Container,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

//import Actions
import { addToast } from "redux/commonRedux/commonActions";
import { logoutUser } from "redux/userRedux/userActions";
import { saveSettings } from "redux/profileRedux/profileActions";

//import scss
import "./profile.scss";

//import utils
import { getDateTime } from "utils/tools";

//import version
import pkg from "../../package.json";

function Profile() {
  const { t, i18n } = useTranslation();

  // // ----------redux store useDispatch & useSelector --------------------
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  let user = state.common.user;

  const [language, setLanguage] = useState(user.settings?.language || "en"); //set default lang to english
  const [passwords, setPasswords] = useState({ old: "", new: "", repeat: "" });
  const [errors, setErrors] = useState({ old: "", new: "", repeat: "" });
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showNewConfirmPassword, setShowNewConfirmPassword] = useState(false);

  useEffect(() => {
    formValidation();
    i18n.changeLanguage(language);
    // eslint-disable-next-line
  }, [passwords, language]);

  const formValidation = () => {
    let newErrors = {
      old: "",
      new: "",
      repeat: "",
    };
    //if all passwords are blank, do not display errors
    if (
      passwords.old === "" &&
      passwords.new === "" &&
      passwords.repeat === ""
    ) {
      setErrors(newErrors);
      return;
    }
    if (passwords.old === "") {
      newErrors.old = t("screens.profile.text_error_password_cannot_be_blank");
    }
    if (passwords.new === "") {
      newErrors.new = t("screens.profile.text_error_password_cannot_be_blank");
    }
    if (passwords.repeat === "") {
      newErrors.repeat = t(
        "screens.profile.text_error_password_cannot_be_blank"
      );
    }
    if (passwords.new !== passwords.repeat) {
      newErrors.repeat = t("screens.profile.text_error_password_mismatch");
    } else {
      newErrors.repeat = "";
    }
    //TODO: Add password regex
    setErrors(newErrors);
  };

  const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
  };

  const handleClickShowCurrentPassword = () => {
    setShowCurrentPassword(!showCurrentPassword);
  };

  const handleClickShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handleClickShowNewConfirmPassword = () => {
    setShowNewConfirmPassword(!showNewConfirmPassword);
  };

  const handlePasswords = (e) => {
    const { name, value } = e.target;
    setPasswords({ ...passwords, [name]: value });
  };

  const onSettingsSave = () => {
    //check if errors
    if (errors.new !== "" || errors.repeat !== "" || errors.old !== "") {
      dispatch(
        addToast({
          type: "error",
          message: "screens.profile.toast_message_settings_save_error",
        })
      );
      return;
    }
    //add check if passwords are the same
    dispatch(
      saveSettings({
        oldPassword: passwords.old,
        newPassword: passwords.new,
        language: language,
      })
    );
    //reset all?
  };

  // const CompileDownloadLink = () => {
  //   try {
  //     const OwnPRNameRegex =
  //       /https:\/\/(\d*)\.pr\.dev\.horizons\.millisecond\.fi.*/gm;
  //     const OwnDevelopmentNameRegex =
  //       /https:\/\/(\w*)\.dev\.horizons\.millisecond\.fi.*/gm;
  //     const OwnProductionNameRegex =
  //       /https:\/\/(\w*)\.horizons\.millisecond\.fi.*/gm;
  //     const OwnNewProductionNameRegex = /https:\/\/(\w*)\.horizons\.fi.*/gm;

  //     let DeploymentName;
  //     let url;
  //     const Bucket = process.env.ANDROID_APK_BUCKET || 'horizons-apk-production';
  //     const Pr = OwnPRNameRegex.exec(window.location.href);
  //     const Development = OwnDevelopmentNameRegex.exec(window.location.href);
  //     const Production = OwnProductionNameRegex.exec(window.location.href);
  //     const NewProduction = OwnNewProductionNameRegex.exec(
  //       window.location.href,
  //     );
  //     if (Pr) {
  //       DeploymentName = Pr[1];
  //       url = `https://storage.googleapis.com/${Bucket}/horizons-origin-pr-${DeploymentName}-v${pkg.version}.apk`;
  //     } else if (Development) {
  //       url = `https://storage.googleapis.com/${Bucket}/horizons-origin-development-v${pkg.version}.apk`;
  //     } else if (Production) {
  //       DeploymentName = Production[1];
  //       url = `https://storage.googleapis.com/${Bucket}/horizons-origin-${DeploymentName}-v${pkg.version}.apk`;
  //     } else if (NewProduction) {
  //       DeploymentName = NewProduction[1];
  //       url = `https://storage.googleapis.com/${Bucket}/horizons-origin-${DeploymentName}-v${pkg.version}.apk`;
  //     }
  //     if (DeploymentName === 'terramining') {
  //       url = `https://storage.googleapis.com/${Bucket}/horizons-origin-dca-v${pkg.version}-${DeploymentName}.apk`;
  //     }
  //     if (url) {
  //       return (
  //         <a href={url}>
  //           <Typography variant="body2">Download APK</Typography>
  //         </a>
  //       );
  //     }
  //   } catch (e) {
  //     console.log(e);
  //   }
  //   return <></>;
  // };

  const CompileDownloadLink = () => {
    try {
      const getDeploymentName = () => {
        let deployment;
        let baseURL = window.location.origin;
        if (baseURL.includes('localhost')) {
          deployment = 'development';
        }
        else {
          let strippedBaseURL = baseURL.replace('https://', '').replace('.horizonsdca.co', '');
          if (strippedBaseURL === 'sys-demo') {
            deployment = 'development';
          }
          else if (strippedBaseURL === 'demo') {
            deployment = 'demo';
          }
        }
        return deployment;
      }
      const Bucket = 'sys-horizon-apk';
      const Deployment = getDeploymentName();
      let url = `https://storage.googleapis.com/${Bucket}/horizons-origin-${Deployment}-v${pkg.version}.apk`;
      if (url) {
        return (
          <a href={url}>
            <Typography variant="body2">Download APK</Typography>
          </a>
        );
      }
    }
    catch (e) {
      console.error(e);
    }
  };

  if (!user.username) {
    return <Navigate to="/login" replace />;
  }
  return (
    <Container className="profileContainer">
      <Typography variant="h4">
        {t("screens.profile.text_title_profile")}
      </Typography>
      <Grid container>
        <Grid item xs={6}>
          <Container className="userData">
            <Typography variant="h5">
              {t("screens.profile.text_title_userdata")}
            </Typography>
            <List dense={true}>
              <ListItem>
                <ListItemText
                  primary={user.fullname}
                  secondary={t("screens.profile.text_secondary_fullname")}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={getDateTime(new Date(user.validity * 1000))}
                  secondary={t(
                    "screens.profile.text_secondary_session_expires"
                  )}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={user.username}
                  secondary={t("screens.profile.text_secondary_username")}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={user.roles.join(", ")}
                  secondary={t("screens.profile.text_secondary_roles")}
                />
              </ListItem>
              {/*<ListItem>
                                <ListItemText primary={user.groups.join(', ')} secondary={t('screens.profile.text_secondary_groups')}/>
                            </ListItem>*/}
            </List>
            <Button
              onClick={() => dispatch(logoutUser())}
              variant="contained"
              color="inherit"
            >
              {t("screens.profile.button_logout")}
            </Button>
          </Container>
        </Grid>
        <Grid item xs={6}>
          <Container className="userSettings">
            <Typography variant="h5">
              {t("screens.profile.text_title_usersettings")}
            </Typography>
            <List dense={true}>
              <ListItem>
                <>
                  <Select
                    value={language}
                    onChange={handleLanguageChange}
                    className="langageSelect"
                    variant="standard"
                  >
                    <MenuItem value={"en"}>
                      {t("screens.profile.select_option_english")}
                    </MenuItem>
                    <MenuItem value={"fr"}>
                      {t("screens.profile.select_option_french")}
                    </MenuItem>
                  </Select>
                  <FormHelperText>
                    {t("screens.profile.select_language")}
                  </FormHelperText>
                </>
              </ListItem>
            </List>
            <Typography variant="h6">
              {t("screens.profile.text_title_password")}
            </Typography>
            <List dense={true}>
              <ListItem>
                <TextField
                  value={passwords.old}
                  onChange={handlePasswords}
                  type={showCurrentPassword ? "text" : "password"}
                  name="old"
                  error={errors.old !== ""}
                  helperText={
                    errors.old !== ""
                      ? errors.old
                      : t("screens.profile.textfield_old_password")
                  }
                  className="pwdTextField"
                  variant="standard"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          onClick={handleClickShowCurrentPassword}
                        >
                          {showCurrentPassword ? (
                            <Visibility sx={{ fontSize: "23px" }} />
                          ) : (
                            <VisibilityOff sx={{ fontSize: "23px" }} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </ListItem>
              <ListItem>
                <TextField
                  value={passwords.new}
                  onChange={handlePasswords}
                  type={showNewPassword ? "text" : "password"}
                  name="new"
                  error={errors.new !== ""}
                  helperText={
                    errors.new !== ""
                      ? errors.new
                      : t("screens.profile.textfield_new_password")
                  }
                  className="pwdTextField"
                  variant="standard"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          onClick={handleClickShowNewPassword}
                        >
                          {showNewPassword ? (
                            <Visibility sx={{ fontSize: "23px" }} />
                          ) : (
                            <VisibilityOff sx={{ fontSize: "23px" }} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </ListItem>
              <ListItem>
                <TextField
                  value={passwords.repeat}
                  onChange={handlePasswords}
                  type={showNewConfirmPassword ? "text" : "password"}
                  name="repeat"
                  error={errors.repeat !== ""}
                  helperText={
                    errors.repeat !== ""
                      ? errors.repeat
                      : t("screens.profile.textfield_new_password_repeat")
                  }
                  className="pwdTextField"
                  variant="standard"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          onClick={handleClickShowNewConfirmPassword}
                        >
                          {showNewConfirmPassword ? (
                            <Visibility sx={{ fontSize: "23px" }} />
                          ) : (
                            <VisibilityOff sx={{ fontSize: "23px" }} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </ListItem>
            </List>
            <Button
              variant="contained"
              color="primary"
              onClick={onSettingsSave}
            >
              {t("screens.profile.button_save")}
            </Button>
          </Container>
        </Grid>
      </Grid>
      <Typography variant="body2">
        {t("screens.profile.text_title_version") + pkg.version}
      </Typography>
      <CompileDownloadLink />
    </Container>
  );
}

export default Profile;
